import React, { useState } from "react";
import profilePic from "../../images/happy-birthday-background-sign-tropical-beach-hawaii-63372910.jpg.webp";
import "../../styling/InvoiceTab.css";
import { ImDownload3 } from "react-icons/im";

const TemplateTab = ({brandingColours}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    
    <div style={{ width: "88%" }} className="templateClientContainer">
      

      <div className="templateClientLeftPanel">
      <img
        src={profilePic}
        alt="cover"
        style={{ width: "100%", height: "224px" }}
        className="client-template-cover-image"
      />
        <div
          className="invoiceContent"
          style={{ borderTop: "hidden", border: "1px solid #D9D9D9" }}
        >
          <div
            className="right-panel-content"
            style={{
              marginTop: "0px",
              padding: "20px",
              background: "linear-gradient(to right, #1C1154, #260F99)",
            }}
          >
            <div className="right-panel-arrow-container">
              <span>
                <h2
                  style={{
                    color: "#FFFFFF",
                    marginTop: "0px",
                    marginBottom: "5px",
                    fontWeight: "425",
                  }}
                >
                  Google Inc.
                </h2>
              </span>{" "}
              <h4
                style={{
                  alignSelf: "flex-end",
                  marginRight: "10px",
                  marginBottom: "5px",
                  color: "#FFFFFF",
                  fontWeight: "425",
                  marginTop: "auto",
                }}
              >
                {" "}
                08/06/2023 05:00pm PST
              </h4>
            </div>
          </div>

          <div
            className="right-panel-content"
            style={{
              marginTop: "5px",
              padding: "20px",
              background: "#F2F2F2",
            }}
          >
            <div className="right-panel-arrow-container">
              <span>
                <h2
                  style={{
                    color: "#1C1154",
                    marginTop: "0px",
                    marginBottom: "5px",
                    fontWeight: "425",
                  }}
                >
                  John Wick
                </h2>
              </span>{" "}
              <h4
                style={{
                  alignSelf: "flex-end",
                  marginRight: "10px",
                  marginBottom: "5px",
                  color: "#1C1154",
                  fontWeight: "425",
                  marginTop: "auto",
                }}
              >
                {" "}
                08/06/2023 05:00pm PST
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="templateClientRightPanel">
      <div >
        <div>
          <div className="right-panel-content" style={{  height: "42px", borderRadius: '5px', paddingTop: '0px !important' }}>
            <div className={`dropdown ${isOpen ? "open" : ""}`}>
              <div className="dropdown-text" onClick={toggleDropdown}>
                Version 2
              </div>
              {isOpen && (
                <div className="dropdown-content">
                  <a href="#">Version 1</a>
                  <a href="#">Version 2</a>
                  <a href="#">Version 3</a>
                </div>
              )}
              <div className="arrow-down" onClick={toggleDropdown}>
                &#9660;
              </div>
            </div>
          </div>
          <div style={{ marginTop: "15px", marginBottom: "30px" }}>
          <button
            style={{
              marginTop: "0px",
              width: "100%",
              height: "35px",
              background: brandingColours?.primaryColor,
              color: brandingColours?.secondaryColor,
              fontSize: "15px",
              border: "hidden",
              cursor: 'pointer',
              borderRadius: "5px",
            }}
            className="events-button-hover-underline"
          >
            <span>
              {" "}
              <ImDownload3 />{" "}
            </span>{" "}
            Download
          </button>
        </div>
        </div>
      </div>
      </div>
    </div>
     );
};

export default TemplateTab;
