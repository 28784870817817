import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess, loginFailure } from '../../authSlice';
import { useLocation } from 'react-router-dom';
import '../../styling/SignUp.css';
import '../../styling/PaymentSuccess.css';
import '../../styling/ClientPortal.css'

import { useNavigate } from 'react-router-dom';

const ClientLogin = () => {

  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  const [email, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");

  const handleLogin = async () => {
    try {
      // Perform login
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/client-user/login`,
        { email, password },
        { withCredentials: true }
      );
      dispatch(loginSuccess(response.data));
  
      const userId = response.data.userId; // Assuming login response includes userId
  
      // Fetch user data
      const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/client-user`, { withCredentials: true });
      const userData = userResponse.data;
      // Check if the user is verified
      if (!userData.data.verified) {
        navigate('/verify-email', { state: { userId } });
        return;
      }
  
      // Fetch events for the logged-in user
      const eventsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/events`, { withCredentials : true }
      );
  
      const events = eventsResponse.data.data;
      console.log(events);
      if (events.length > 0) {
        const hasPaidEvent = events.some(event => event.paymentStatus === 'paid');
  
        if (hasPaidEvent || events.length > 0) {
          navigate('/client-home', { state: { events } });
        } else {
          const unpaidEvent = events[0];
          navigate('/booth2', { state: { event: unpaidEvent } });
        }
      } else {
        console.log('No events found');
      }
    } catch (error) {
      console.error('Login or event fetching error:', error);
      dispatch(loginFailure(error.message));
    }
  };

      const boothRightPanel = (
        <div
          className="success-right-panel"
          style={{
            width: "19%",
            background: "#FFFFFF",
            marginTop: "145px",
            height: "fitContent",
            borderLeft: 'hidden',

          }}
        >
          <div
            className="right-panel-content"
            style={{ marginTop: "0px", padding: "20px" }}
          >
            <div className="right-panel-arrow-container">
              <span>
                <h4
                  style={{
                    color: "#1C1154",
                    marginTop: "0px",
                    marginBottom: "5px",
                    fontWeight: "425",
                  }}
                >
                  Bronze Package
                </h4>
              </span>{" "}
              <h4
                style={{
                  alignSelf: "flex-end",
                  marginRight: "10px",
                  marginBottom: "5px",
                  color: "#1C1154",
                  fontWeight: "425",
                  marginTop: "auto",
                }}
              >
                {" "}
                $500.00
              </h4>
            </div>
          </div>
          <div
            className="right-panel-content"
            style={{ marginTop: "20px", padding: "20px" }}
          >
            <div className="right-panel-arrow-container">
              <span>
                <h4
                  style={{
                    color: "#1C1154",
                    marginTop: "0px",
                    marginBottom: "5px",
                    fontWeight: "425",
                  }}
                >
                  Custom Party Props
                </h4>
              </span>{" "}
              <h4
                style={{
                  alignSelf: "flex-end",
                  marginRight: "10px",
                  marginBottom: "5px",
                  color: "#1C1154",
                  fontWeight: "425",
                  marginTop: "auto",
                }}
              >
                {" "}
                $50.00
              </h4>
            </div>
          </div>
            <div>
              <p
                style={{
                  color: "#625C73",
                  marginTop: "20px",
                  marginBottom: "5px",
                  fontWeight: "425",
                }}
              >
                Nov 23, 2023
              </p>
          </div>
            <div>
              <p
                style={{
                  color: "#625C73",
                  marginTop: "0px",
                  marginBottom: "5px",
                  fontWeight: "425",
                }}
              >
                12:00 PM to 04:00 PM
              </p>
          </div>
    
          
            <div>
              <p
                style={{
                  color: "#625C73",
                  marginTop: "0px",
                  marginBottom: "5px",
                  fontWeight: "425",
                }}
              >
                1234 Kumquat St. Riverside, CA 92503
              </p>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", color: "#4D4D4D" }}
          >
            <p>Payment recieved successfully! </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#4D4D4D",
            }}
          >
            <div> Sliver Package</div>
            <div> $500</div>
          </div>
    
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#4D4D4D",
              marginTop: "10px",
              borderBottom: "1px solid #D9D9D9",
            }}
          >
            <div> Custom Party Props</div>
            <div style={{ marginBottom: "5px" }}> $50</div>
          </div>
    
          <div
            style={{
              height: "80px",
              backgroundColor: "#FFFFFF",
              marginBottom: "20px",
              paddingTop: "5%",
              width: "100%",
            }}
          >
            <div
            style={{ display: "flex", justifyContent: "center", color: "#4D4D4D", fontWeight: 'bold' }}
          >
            <p>Total Amount:  $550 </p>
          </div>
          </div>
        </div>
      );
    
      return (
        <div className='paymentSuccess'>
        <div className='login'>
          <div style={{ padding: '13%' }}>
            <div style={{ fontSize: '35px', color: '#1C1154', marginBottom: '20px' }}>
            Login
            </div>
            <div style={{fontSize: '15px', color: '#4D4D4D', }}>
            {/* We emailed the detailed to boothcompany@email.com */}
            </div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="login-input"
              value={email}
              onChange={(e) => setUsername(e.target.value)}
            />

            <input
              type="text"
              name="password"
              placeholder="Password"
              className="login-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div style={{ paddingLeft: '30%', color: '#545454'}}>
            <p><span style={{ textDecoration: 'underline' }}>forgot password? </span></p>
            </div>
            <button className="login-button" onClick={handleLogin}>
              Login
            </button>
        </div>
        </div>
        {/* {boothRightPanel} */}
        </div>
      );
};

export default ClientLogin;







