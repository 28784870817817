import React from "react";
import "../styling/BookABooth.css";

import profilePic from "../images/John-Wick.jpg.avif";
import BookingHeader from "./clientPortal/BookingHeader";
import { useNavigate, useLocation } from "react-router-dom";


const BookABooth2 = () => {
  const navigate = useNavigate(); 
  const location = useLocation();
  const event = location.state?.event;
  console.log(event);

 
    const optionsDate = { month: '2-digit', day: '2-digit', year: '2-digit' };
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };
  
    const eventDate = new Date(event.eventDate);
    const startTime = new Date(event.startTime);
    const endTime = new Date(event.endTime);

  // useEffect(() => {
  //   fetchEvents();
  // }, []);

  // const fetchEvents = async () => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/business/get-addon?businessId=${businessId}`
  //     );
  //     if (!response.ok) throw new Error("Failed to fetch add-ons");
  //     const data = await response.json();
  //     setAddons(data);
  //   } catch (err) {
  //     setError(err.message);
  //   } finally {
  //     setLoadingAddons(false);
  //   }
  // };

  const handleBookClick = () => {
    console.log("navigate");
    navigate('/payment', { state: { eventId: event._id, businessId: event.businessId } });
  }


  const packages = (
    <div>
      <div
        className="invoiceContent"
        style={{
          width: "92.7%",
          background: "#FFFFFF",
          height: "fitContent",
        }}
      >
        <h2
          style={{ color: "#1C1154", marginTop: "0px", marginBottom: "30px" }}
        >
          Choosen Package
        </h2>
        <div
          className="right-panel-content"
          style={{ marginTop: "0px", padding: "20px" }}
        >
          <div className="right-panel-arrow-container">
            <span>
              <h2
                style={{
                  color: "#1C1154",
                  marginTop: "0px",
                  marginBottom: "5px",
                  fontWeight: "425",
                }}
              >
                Bronze Package
              </h2>
            </span>{" "}
            <h4
              style={{
                alignSelf: "flex-end",
                marginRight: "10px",
                marginBottom: "5px",
                color: "#1C1154",
                fontWeight: "425",
                marginTop: "auto",
              }}
            >
              {" "}
              $250.00
            </h4>
          </div>

          <div style={{ color: "#4D4D4D" }}>
            <p style={{ marginBottom: "3px" }}>3 Hours On-Site Attendant</p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Unlimited Prints
            </p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Online Downloads
            </p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Party Props Custom
            </p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Photostrips Design
            </p>
          </div>
        </div>
        <div
          className="bordered-box"
          style={{ marginBottom: "10px", marginTop: "20px" }}
        >
          <img src={profilePic} alt="Your Image" className="image" />
          <div className="content">
            <div style={{ color: "#1C1154" }}> Custom Party Props </div>
            <p
              style={{
                color: "#4D4D4D",
                marginTop: "7px",
                marginBottom: "5px",
              }}
            >
              Let us know what theme and colors{" "}
            </p>
            <p style={{ color: "#4D4D4D", marginTop: "0px" }}>
              {" "}
              you want and we’ll make it happen!
            </p>
          </div>
          <div>
            <p
              className="rightmost-content"
              style={{ marginTop: "-20px", color: "#1C1154" }}
            >
              $150.00
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#ffffff",
          width: "83.8%",
          border: "1px solid #D9D9D9",
          marginTop: "35px",
          padding: "8%",
        }}
      >
        <h2 style={{ color: "#1C1154", fontWeight: "450", marginTop: "0px" }}>
          {" "}
          Event Info
        </h2>
        <input
          placeholder={event.eventName}
          style={{
            border: "hidden",
            border: "1px solid #D4D4D4",
            fontSize: "15px",
            width: "100%",
            height: "50px",
          }}
        ></input>

        <input
          placeholder={event.eventType || "Birthday"}
          style={{
            border: "hidden",
            border: "1px solid #D9D9D9",
            fontSize: "15px",
            width: "100%",
            height: "50px",
            marginTop: "10px",
          }}
        ></input>

        <input
          placeholder={eventDate.toLocaleDateString('en-US', optionsDate)}
          style={{
            border: "hidden",
            border: "1px solid #D9D9D9",
            fontSize: "15px",
            width: "100%",
            height: "50px",
            marginTop: "10px",
          }}
        ></input>

        <input
          placeholder={startTime.toLocaleTimeString('en-US', optionsTime) + ' - ' + endTime.toLocaleTimeString('en-US', optionsTime)}
          style={{
            border: "hidden",
            border: "1px solid #D9D9D9",
            fontSize: "15px",
            width: "100%",
            height: "50px",
            marginTop: "10px",
          }}
        ></input>

        <input
          placeholder={event.address }
          style={{
            border: "hidden",
            border: "1px solid #D9D9D9",
            fontSize: "15px",
            width: "100%",
            height: "50px",
            marginTop: "10px",
          }}
        ></input>

        <input
          placeholder={event.details || "Sample event details"}
          style={{
            border: "hidden",
            border: "1px solid #D9D9D9",
            fontSize: "15px",
            width: "100%",
            height: "120px",
            marginTop: "10px",
          }}
        ></input>
      </div>
      {/* <div 
            style={{
              backgroundColor: "#ffffff",
              width: "83.6%",
              border: "1px solid #D9D9D9",
              marginTop: "35px",
              padding: "8%",
            }}>
                <h2 style={{ color: "#1C1154", fontWeight: "450", marginTop: "0px" }}>
              {" "}
              Choose How to Pay
            </h2>
                <div style={{ color: '#4D4D4D', padding: '10px', border: '1px solid #D9D9D9' }}>
                    <h4 style={{ color: "#1C1154", fontWeight: "450", marginTop: "0px", marginBottom: '10px' }}> Pay In Full</h4>
                    Pay the complete total of booking ($550) and you’re booked!
                </div>
                <div style={{ color: '#4D4D4D', padding: '10px', border: '1px solid #D9D9D9', marginTop: '20px' }}>
                    <h4 style={{ color: "#1C1154", fontWeight: "450", marginTop: "0px", marginBottom: '10px' }}> Pay initial deposit now</h4>
                    $225 , 50% of ($550) is due today and final $225 payment on Nov 11, 2023
                </div>
          </div> */}

      {/* <div
        style={{
          backgroundColor: "#ffffff",
          width: "83.8%",
          border: "1px solid #D9D9D9",
          marginTop: "35px",
          padding: "8%",
        }}
      >
        <h2 style={{ color: "#1C1154", fontWeight: "450", marginTop: "0px" }}>
          {" "}
          Pay With
        </h2>

        <h4 style={{ color: "#1C1154", marginTop: "35px" }}> Card Number</h4>

        <input
          placeholder="   Card Number"
          style={{
            border: "hidden",
            border: "1px solid #D9D9D9",
            fontSize: "15px",
            width: "100%",
            height: "50px",
          }}
        ></input>

        <h4 style={{ color: "#1C1154", marginTop: "35px" }}> Expiration</h4>

        <input
          placeholder=" Expiration"
          style={{
            border: "hidden",
            border: "1px solid #D9D9D9",
            fontSize: "15px",
            width: "100%",
            height: "50px",
          }}
        ></input>

        <h4 style={{ color: "#1C1154", marginTop: "35px" }}> Zip Code</h4>

        <input
          placeholder="  Zip Code"
          style={{
            border: "hidden",
            border: "1px solid #D9D9D9",
            fontSize: "15px",
            width: "100%",
            height: "50px",
          }}
        ></input>

        <h4 style={{ color: "#1C1154", marginTop: "35px" }}> Country/Region</h4>

        <input
          placeholder="  United States"
          style={{
            border: "hidden",
            border: "1px solid #D9D9D9",
            fontSize: "15px",
            width: "100%",
            height: "50px",
          }}
        ></input>
      </div> */}

      <div
        style={{
          backgroundColor: "#ffffff",
          width: "83.8%",
          border: "1px solid #D9D9D9",
          marginTop: "35px",
          padding: "8%",
        }}
      >
        <h2 style={{ color: "#1C1154", fontWeight: "450", marginTop: "0px" }}>
          {" "}
          Cancellation Policy
        </h2>
        <div style={{ color: "#4D4D4D", padding: "10px", display: "flex" }}>
          <p>
            Free cancellation before Dec 15. Cancel before check-in on Dec 20
            for a partial refund.{" "}
            <span style={{ color: "#1C1154" }}>Learn more</span>{" "}
          </p>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#ffffff",
          width: "83.8%",
          border: "1px solid #D9D9D9",
          marginTop: "35px",
          padding: "8%",
        }}
      >
        <h2 style={{ color: "#1C1154", fontWeight: "450", marginTop: "0px", marginBottom: '0px' }}>
          {" "}
          Make Payment
        </h2>
        <div style={{ color: "#4D4D4D", padding: "10px", display: "flex" }}>
          <p>
          By selecting the button, I agree to the booking terms. I also agree to the updated Terms of Service, Payments Terms of Service, and I acknowledge the Privacy Policy.
          </p>
        </div>
        <div>
        <button
          style={{
            marginTop: "15px",
            height: "40px",
            width: "100%",
            background: "#260F99",
            border: "1px solid #43425D ",
            color: "#FFFFFF",
            fontSize: "17px",
            fontWeight: "450",
          }}
          onClick={handleBookClick}
        >
          Book Your Booth
        </button>
        </div>
      </div>
    </div>
  );

  const boothRightPanel = (
    <div
      className="invoiceContent"
      style={{
        width: "87.7",
        background: "#FFFFFF",
        marginTop: "30px",
        borderLeft: "hidden",
        borderRight: "hidden",
      }}
    >
      <div
        className="right-panel-content"
        style={{ marginTop: "0px", padding: "20px" }}
      >
        <div className="right-panel-arrow-container">
          <span>
            <h4
              style={{
                color: "#1C1154",
                marginTop: "0px",
                marginBottom: "5px",
                fontWeight: "425",
              }}
            >
              Bronze Package
            </h4>
          </span>{" "}
          <h4
            style={{
              alignSelf: "flex-end",
              marginRight: "10px",
              marginBottom: "5px",
              color: "#1C1154",
              fontWeight: "425",
              marginTop: "auto",
            }}
          >
            {" "}
            $250.00
          </h4>
        </div>
      </div>
      <div
        className="right-panel-content"
        style={{ marginTop: "20px", padding: "20px" }}
      >
        <div className="right-panel-arrow-container">
          <span>
            <h4
              style={{
                color: "#1C1154",
                marginTop: "0px",
                marginBottom: "5px",
                fontWeight: "425",
              }}
            >
              Custom Party Props
            </h4>
          </span>{" "}
          <h4
            style={{
              alignSelf: "flex-end",
              marginRight: "10px",
              marginBottom: "5px",
              color: "#1C1154",
              fontWeight: "425",
              marginTop: "auto",
            }}
          >
            {" "}
            $50.00
          </h4>
        </div>
      </div>
      <div
        className="right-panel-content"
        style={{ marginTop: "20px", padding: "10px" }}
      >
        <div className="right-panel-arrow-container">
          <p
            style={{
              color: "#625C73",
              marginTop: "0px",
              marginBottom: "5px",
              fontWeight: "425",
            }}
          >
            MM/DD/YY
          </p>
        </div>
      </div>
      <div
        className="right-panel-content"
        style={{ marginTop: "20px", padding: "10px" }}
      >
        <div className="right-panel-arrow-container">
          <p
            style={{
              color: "#625C73",
              marginTop: "0px",
              marginBottom: "5px",
              fontWeight: "425",
            }}
          >
            00:00 PM - 04:00 PM
          </p>
        </div>
      </div>

      <div
        className="right-panel-content"
        style={{ marginTop: "20px", padding: "10px" }}
      >
        <div className="right-panel-arrow-container">
          <p
            style={{
              color: "#625C73",
              marginTop: "0px",
              marginBottom: "5px",
              fontWeight: "425",
            }}
          >
            1234 Kumquat St. Riverside, CA 92503
          </p>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", color: "#4D4D4D" }}
      >
        <p>You won’t be charged yet</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "#4D4D4D",
        }}
      >
        <div> Sliver Package</div>
        <div> $500</div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "#4D4D4D",
          marginTop: "10px",
          borderBottom: "1px solid #D9D9D9",
        }}
      >
        <div> Custom Party Props</div>
        <div style={{ marginBottom: "5px" }}> $50</div>
      </div>

      <div
        style={{
          height: "80px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          paddingTop: "5%",
          width: "100%",
        }}
      >
        <button
          style={{
            marginTop: "35px",
            height: "40px",
            width: "100%",
            background: "#260F99",
            border: "1px solid #43425D ",
            color: "#FFFFFF",
            fontSize: "17px",
            fontWeight: "450",
            cursor: "pointer"
          }}
          onClick={handleBookClick}
        >
          Book Your Booth
        </button>
      </div>
    </div>
  );

  return (
    <div>
      <BookingHeader />
      <div className="bookAbooth">
        <div
          style={{
            border: "1px solid #D9D9D9",
            background: "#F2F2F2",
            width: "44%",
            marginLeft: "12%",
          }}
        >
          {" "}
          {packages}
        </div>
        <div
          style={{
            border: "1px solid #D9D9D9",
            background: "#FFFFFF",
            width: "25%",
            marginRight: "12%",
            height: "696px",
          }}
        >
          {" "}
          {boothRightPanel}
        </div>
      </div>
    </div>
  );
};

export default BookABooth2;
