import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../../styling/ClientPortal.css';
import GalleryTab from "../tabs/GalleryTab";
import ClientEventsTable from "../tables/ClientEventsTable";

const ClientLandingPage = ({ brandingColours, handleTabSwitch, events }) => {
  console.log(events);
  return (
    <div style={{ paddingTop: '15px'}} className="clientLandingPageTabs">
      <Tabs>
        <TabList>
          <Tab>Events</Tab>
          <Tab>Gallery</Tab>
        </TabList>

        <TabPanel>
          <ClientEventsTable
            event={events}
            brandingColours={brandingColours}
            onEventClick={() => handleTabSwitch('portal')} // Switch to the ClientPortal
          />
        </TabPanel>
        <TabPanel>
          <GalleryTab brandingColours={brandingColours} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default ClientLandingPage;