import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../../styling/ClientPortal.css'; // Ensure this file exists and the path is correct
import GalleryTab from "../tabs/GalleryTab"; // Ensure this file exists
import InvoiceTab from "../tabs/InvoiceTab"; // Ensure this file exists
import PackagesTab from "../tabs/PackagesTab"; // Ensure this file exists
import TemplateTab from "../tabs/TemplateTab"; // Ensure this file exists
import OverviewTabBusiness from "../tabs/OverviewTabBusiness";
import QuestionnaireTabBusiness from "../tabs/QuestionnaireTabBusiness";
const ClientPortalBusiness = ({brandingColours}) => {

    const [hoveredTab, setHoveredTab] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredTab(index);
    };

    const handleMouseLeave = () => {
        setHoveredTab(null);
    };

    return (
        <div style={{ display: 'flex' }}>
            <div className="clientLandingTabs" style={{ paddingLeft: '10px', width: "90%" }}>
                <Tabs>
                <TabList>
                        {['Overview', 'Invoice', 'Packages', 'Questionnaire', 'Template', 'Gallery'].map((tab, index) => (
                            <Tab
                                key={index}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                    color: hoveredTab === index ? brandingColours?.primaryColor : 'initial',
                                    transition: 'color 0.3s ease',
                                }}
                            >
                                {tab}
                            </Tab>
                        ))}
                    </TabList>

                    <TabPanel>
                        <OverviewTabBusiness brandingColours={brandingColours} />
                    </TabPanel>
                    <TabPanel>
                        <InvoiceTab brandingColours={brandingColours} />
                    </TabPanel>
                    <TabPanel>
                        <PackagesTab brandingColours={brandingColours} />
                    </TabPanel>
                    <TabPanel>
                        <QuestionnaireTabBusiness brandingColours={brandingColours} />
                    </TabPanel>
                    <TabPanel>
                        <TemplateTab brandingColours={brandingColours} />
                    </TabPanel>
                    <TabPanel>
                        <GalleryTab brandingColours={brandingColours} />
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    );
}

export default ClientPortalBusiness;