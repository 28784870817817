// import React, { useEffect, useState } from 'react';
// import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
// import moment, { now } from 'moment';
// import 'react-big-calendar/lib/css/react-big-calendar.css';
// import axios from 'axios';

// const localizer = momentLocalizer(moment);

// const MyCalendar = () => {
//     const [view, setView] = useState('month');

//     const [ calenderEvents, setCalenderEvents] = useState([]);

//     // useEffect hook to fetch event details when component mounts
//   useEffect(() => {
//     fetchCalenderEvents();
//   }, []);

//     const handleView = (newView) => {
//       setView(newView);
//     };

//     const fetchCalenderEvents = async () => {
//       try{
//         const response = await axios.get('${process.env.REACT_APP_API_URL}/business/events', { withCredentials: true});
//         console.log(response.data.data);
//         setCalenderEvents(response.data.data);
  
//         console.log(calenderEvents);
//       }
//       catch(error) {
//         console.log('Something went wrong while fetching latest events....', error);
//       }
//     }

//     const handleEvents = () => {
//       if (calenderEvents !== null) {
//           const eventsData = calenderEvents.map(event => ({
//               id: event._id,
//               title: event.eventName,
//               start: now,
//               end: now,
//               // Add more properties as needed
//           }));
//           console.log(eventsData); // Log the generated eventsData
//           return eventsData;
//       }
//       return []; // Return an empty array if calenderEvents is null
//   }

//   return (
//     <div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
//            <h1 style={{ color: '#1C1154', marginTop:'30px', marginBottom:'30px' }}>Calender</h1>
//         <Calendar
//         style={{ background: '#FFFFFF' }}
//         localizer={localizer}
//         events={handleEvents()}
//         startAccessor="start"
//         endAccessor="end"
//         views={{
//           month: true,
//           week: true,
//           day: true,
//         }}
//         view={view}
//         onSelectSlot={(slotInfo) => console.log('Selected slot:', slotInfo)}
//         onView={(newView) => handleView(newView)}
//         showAllDay
//         step={30}
//         defaultDate={now}
//       />
//     </div>
//   );
// };

// export default MyCalendar;


import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        // Assuming fetchEvents is a function that fetches your events from the API
        fetchEvents().then(data => {
            const formattedEvents = data?.map(event => ({
                id: event._id,
                title: event.eventName,
                start: new Date(event.startTime),
                end: new Date(event.endTime),
                // Add more properties as needed
            }));
            setEvents(formattedEvents);
            console.log(formattedEvents)
        });
    }, []);

    const fetchEvents = async () => {
      try {
         const response = await axios.get(`${process.env.REACT_APP_API_URL}/business/events`, { withCredentials: true});
         const data = await response.data.data;
         console.log(data);
         return data;
      } catch (error) {
         console.error('There has been a problem with your fetch operation:', error);
      }
     }

    return (
        <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            views={['month', 'week', 'day']}
            style={{ height: 500 }}
        />
    );
};

export default MyCalendar;