import React from "react";
import "../../styling/Dashboard.css";

import { ImDownload3 } from "react-icons/im";

const InvoiceRightPanel = ({ brandingColours }) => {
  return (
    <div>
      <div>
        <div className="right-panel-content" style={{marginTop: "0px", paddingLeft: "15%"}}>
        <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              className="event-dues-box"
            >
              <div>
                <h4
                  style={{
                    color: "#000000",
                    marginTop: "0px",
                    marginBottom: "5px",
                    fontWeight: "525",
                  }}
                >
                  Balance Due
                </h4>
                <h1
                  style={{
                    color: "#000000",
                    marginBottom: "20px",
                    marginTop: "5px",
                  }}
                >
                  {" "}
                  $0.00
                </h1>
              </div>
              <div
                style={{
                  backgroundColor: "#E1FAE3",
                  color: "#21A62C",
                  padding: "10px",
                  height: "20px",
                  marginRight: "20px",
                  alignSelf: "initial",
                }}
              >
                Paid
              </div>
              </div>
        </div>
        <div
          className="right-panel-content"
          style={{
            marginTop: "0px",
            borderTop: "hidden",
            borderBottom: "hidden",
          }}
        >
          <div className="right-panel-arrow-container">
            <span>
              <p
                style={{
                  color: "#4D4D4D",
                  marginTop: "0px",
                  marginBottom: "5px",
                }}
              >
                Original Invoice Total
              </p>
            </span>{" "}
            <div
              style={{
                alignSelf: "flex-end",
                fontSize: "15px",
                marginRight: "30px",
                marginBottom: "5px",
                color: "#4D4D4D",
              }}
            >
              {" "}
              $250.00{" "}
            </div>
          </div>
        </div>

        <div
          className="right-panel-content"
          style={{ marginTop: "0px", borderTop: "hidden", paddingTop: "0px" }}
        >
          <div className="right-panel-arrow-container">
            <span>
              <p
                style={{
                  color: "#4D4D4D",
                  marginTop: "0px",
                  marginBottom: "5px",
                }}
              >
                Total amount recieved
              </p>
            </span>{" "}
            <div
              style={{
                alignSelf: "flex-end",
                fontSize: "15px",
                marginRight: "30px",
                marginBottom: "5px",
                color: "#4D4D4D",
              }}
            >
              {" "}
              $250.00{" "}
            </div>
          </div>
        </div>

        <div
          className="right-panel-content"
          style={{ marginTop: "0px", borderTop: "hidden" }}
        >
          <h2 style={{ color: "#000000", fontWeight: "400", marginTop: "0px" }}>
            {" "}
            Invoice Activity
          </h2>
          <h4
            style={{
              color: "#000000",
              fontWeight: "400",
              marginTop: "0px",
              marginBottom: "0px",
            }}
          >
            {" "}
            June 17, 2026
          </h4>
          <p style={{ fontSize: "12px", marginTop: "15px", color: "#4D4D4D" }}>
            {" "}
            11:44 PM{" "}
          </p>
          <p style={{ fontSize: "12px", marginTop: "0px", color: "#4D4D4D" }}>
            {" "}
            Heartbreakent06@yahoo.com made a $175.00 payment.
          </p>
        </div>
      </div>
      <div style={{ marginTop: "0px", width: "65.4%", paddingLeft: "5%" }} className="invoice-download-button-mobile">
        <div style={{ marginTop: "15px", marginBottom: "30px" }}>
          <button
            style={{
              marginTop: "0px",
              width: "100%",
              height: "35px",
              background: brandingColours?.primaryColor,
              color: brandingColours?.secondaryColor,
              fontSize: "15px",
              border: "hidden",
              cursor: 'pointer'
            }}
            className="events-button-hover-underline"
          >
            <span>
              {" "}
              <ImDownload3 />{" "}
            </span>{" "}
            Download Invoice
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoiceRightPanel;
