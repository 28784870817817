import React, { useState } from "react";
import "../../styling/Dashboard.css";

import profilePic from "../../images/profilePic.jpg";
import axios from "axios";

const OverviewRightPanelClient = ({ brandingColours }) => {
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [deductionAmount, setDeductionAmount] = useState("");
  const [refundDetails, setRefundDetails] = useState(null);
  const [error, setError] = useState(null);

  console.log(brandingColours);
  const handleRefund = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/create-refund`, {
        paymentIntentId,
        deductionAmount: parseFloat(deductionAmount),
      });

      setRefundDetails(response.data);
      setError(null);
    } catch (err) {
      setError(err.response ? err.response.data.error : "An error occurred");
      setRefundDetails(null);
    }
  };

  const memberInfo = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: "4px",
        padding: "2px",
      }}
    >
      <div
        style={{ borderRadius: "50%", overflow: "hidden", marginRight: "10px" }}
      >
        {/* Replace the URL with the actual path to your profile picture */}
        <img
          src={profilePic}
          alt="Profile"
          style={{ width: "50px", height: "50px" }}
        />
      </div>
      <div>
        <h4 style={{ margin: "0", fontWeight: "500", color: "#000000" }}>
          John Doe
        </h4>
        <p style={{ margin: "0", color: "#4D4D4D" }}>Software Engineer</p>
      </div>
    </div>
  );

  return (
    <div
    //   className="right-panel"
    //   style={{ marginTop: "79px", width: "20%", height: "auto" }}
    >
      <div>
        <div className="right-panel-content">
          <div className="event-total-div">
            <div className="overview-event-name">
              <h2 style={{marginBottom: '0px', marginTop: '0px'}}>John's Birthday Party 6</h2>
              <span style={{ color: '#4D4D4D', }}>
              Date Booked: March 11, 2023
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              className="event-total-box"
            >
              <div>
                <h4
                  style={{
                    color: "#000000",
                    marginTop: "0px",
                    marginBottom: "5px",
                    fontWeight: "525",
                  }}
                >
                  Event Total:
                </h4>
                <h1
                  style={{
                    color: "#000000",
                    marginBottom: "20px",
                    marginTop: "5px",
                  }}
                >
                  {" "}
                  $400
                </h1>
              </div>
              <div
                style={{
                  backgroundColor: "#E1FAE3",
                  color: "#21A62C",
                  padding: "10px",
                  height: "20px",
                  marginRight: "20px",
                  alignSelf: "initial",
                }}
              >
                Paid
              </div>
            </div>
            <button
              style={{
                width: "92%",
                height: "35px",
                marginLeft: "1%",
                marginBottom: "3%",
                color: brandingColours?.secondaryColor || "#FFFFFF",
                fontWeight: "400",
                fontSize: "15px",
                background: brandingColours?.primaryColor || "#080700",
                border: "1px solid #D9D9D9",
                cursor: "pointer",
              }}
              className="cancel-btn-overview"
            >
              Cancel or Reschedule
            </button>
          </div>
        </div>
        <div className="package-addOn-div">
            <div className="overview-package">
        <div
          className="right-panel-content"
          style={{ marginTop: "0px", borderTop: "hidden" }}
        >
          <div className="right-panel-arrow-container">
            <span>
              <h4
                style={{
                  color: "#000000",
                  marginTop: "0px",
                  marginBottom: "5px",
                  fontWeight: "525",
                }}
              >
                Premier Package
              </h4>
            </span>{" "}
            <div
              style={{
                alignSelf: "flex-end",
                fontSize: "15px",
                marginRight: "10px",
                marginBottom: "5px",
                color: "#0000004",
                fontWeight: "525",
              }}
            >
              {" "}
              $250{" "}
            </div>
          </div>

          <div style={{ color: "#4D4D4D" }}>
            <p style={{ marginBottom: "3px" }}>3 Hours On-Site Attendant</p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Unlimited Prints
            </p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Online Downloads
            </p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Party Props Custom
            </p>
            <p style={{ marginBottom: "10px", marginTop: "3px" }}>
              Photostrips Design
            </p>
          </div>
        </div>
        </div>
        <div className="overview-addOns">
        <div
          className="right-panel-content"
          style={{ marginTop: "0px", borderTop: "hidden" }}
        >
          <div className="right-panel-arrow-container">
            <span>
              <h4
                style={{
                  color: "#000000",
                  marginTop: "0px",
                  marginBottom: "5px",
                  fontWeight: "525",
                }}
              >
                Add Ons
              </h4>
            </span>{" "}
            <div
              style={{
                alignSelf: "flex-end",
                fontSize: "15px",
                marginRight: "10px",
                marginBottom: "5px",
                color: "#0000004",
                fontWeight: "525",
              }}
            >
              {" "}
              $50{" "}
            </div>
          </div>

          <div style={{ color: "#4D4D4D" }}>
            <p>
              Group Photo <br />
              Custom Backdrop
            </p>
          </div>
        </div>
        </div>
        </div>
      </div>
      <div
        style={{
          width: "89.5%",
          border: "1px solid #D9D9D9",
          borderTop: "none",
          padding: "5%",
        }}
        className="overview-members-container"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            width: "87%",
          }}
        >
          <h4
            style={{
              fontWeight: "800",
              color: "#000000",
              marginTop: "0px",
              fontSize: "15px",
            }}
          >
            {" "}
            Assigned Team Members
          </h4>
        </div>
        <div className="overview-team-members">
          {memberInfo}
          {memberInfo}
          {memberInfo}
        </div>
        {/* <div style={{ marginTop: "15px", marginBottom: '30px' }}>
            <button className="addTeamMemberButton" style={{ background: '#ffffff', border: '1px solid #D9D9D9', color: '#0000004', fontWeight: '550'}} onClick={handleRefund}>  Cancel </button>
      </div> */}
      </div>
    </div>
  );
};

export default OverviewRightPanelClient;
