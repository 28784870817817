import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ClientLandingPage from "./ClientLandingPage";
import ClientPortal from "./ClientPortal";
import LeftClientPanel from "../panels/LeftClientPanel";
import axios from "axios";
import ClientHeader from "./ClientHeader";
import "../../styling/App.css";
import LeftPanelClientPortal from "../panels/LeftPanelClientPortal";
import ClientAccountInfo from "./ClientAccountInfo";

const ClientContainer = () => {
  const [client, setClient] = useState({});
  const [brandingColours, setBrandingColours] = useState({
    primaryColor: "#008000",
    secondaryColor: "#dddddd",
  });
  const [selectedTab, setSelectedTab] = useState("landing"); // Flag to control which set of tabs is shown
  const [events, setEvents] = useState([]);
  const [brandLogo, setBrandLogo] = useState(null);
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");

  // Fetch client data and branding on component mount
  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/client-user`, {
          withCredentials: true,
        });
        setClient(response.data.data);
        console.log(client);
      } catch (error) {
        console.log("Error fetching client data: ", error);
      }
    };

    const fetchBusinessBranding = async (businessId) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/client/get-branding`,
          {
            businessId: "65d086889fec7c067fce1292",
          },
          { withCredentials: true }
        );

        // Update branding colors
        const { primaryColor, secondaryColor, brandLogo } = response.data;
        setBrandingColours({ primaryColor, secondaryColor });
        setBrandLogo(brandLogo);
      } catch (error) {
        console.log("Error fetching branding data: ", error);
      }
    };

    const fetchEventData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/client/events`,
          { withCredentials: true }
        );

        setEvents(response.data.data);
        console.log(events);
      } catch (error) {
        console.log("Error fetching branding data: ", error);
      }
    };

    fetchClientData().then(() => {
      fetchEventData();
      if (businessId) {
        fetchBusinessBranding({ businessId });
      }
    });
  }, [client.businessId]);

  // Handle tab switch between landing page and portal
  const handleTabSwitch = (tab) => {
    setSelectedTab(tab);
  };

  const handleBacktoAccount = () => {
    setSelectedTab("landing");
  };

  const handleBackToClientAccount = () => {
    setShowAccountInfo(false);
  };

  const handleAccountDropdownClick = () => {
    setShowAccountInfo(!showAccountInfo);
  };

  return (
    <div className="client-container">
      <div style={{ backgroundColor: "#f2f2f2" }}>
          <ClientHeader
            style={{ backgroundColor: "#ffffff" }}
            brandLogo={brandLogo}
            clientData={client}
            onAccountDropdownClick={handleAccountDropdownClick}
          />
        {!showAccountInfo ? (
          <div className="main-content">
            <div className="clientProfileDetails">
              {/* Left Panel stays constant */}
              <LeftPanelClientPortal
                handleBackToAccount={handleBacktoAccount}
                clientData={client}
                brandingColours={brandingColours}
                selectedTab={selectedTab}
              />
            </div>
            <div className="clientLandingTabs">
              {selectedTab === "landing" ? (
                <ClientLandingPage
                  brandingColours={brandingColours}
                  handleTabSwitch={handleTabSwitch}
                  events={events}
                />
              ) : (
                <ClientPortal brandingColours={brandingColours} />
              )}
            </div>
          </div>
        ) : (
          <ClientAccountInfo
          />
        )}
      </div>
    </div>
  );
};

export default ClientContainer;
