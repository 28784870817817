import React from "react";
import profilePic from "../../images/happy-birthday-background-sign-tropical-beach-hawaii-63372910.jpg.webp";
import FileUploadComponent from "../fileUpload/FileUploadComponent.js";
import OverviewRightPanelClient from "../panels/OverviewRightPanelClient";
import "../../styling/ClientPortal.css";

const OverviewTab = ({ brandingColours }) => {
  const handleFileChange = (e) => {
    const files = e.target.files;
  };

  return (
    <div className="overviewClientContainer">
      <div className="overviewClientLeftPanel">
        <img
          src={profilePic}
          alt="cover"
          style={{ width: "100%", height: "224px" }}
          className="overviewClientLeftPanelImage"
        />
        <div style={{ padding: "20px", fontWeight: "300" }} className="event-overview-div">
          <h2 style={{ color: "#000000" }}>Event Overview</h2>
          <p style={{ width: "100%" }}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit am.
          </p>
        </div>

        <div
          style={{ marginLeft: "25px", fontWeight: "300", color: "#000000" }}
          className="event-overview-div"
        >
          <h2 style={{ fontWeight: "400" }}>Event Details</h2>
          <h4 style={{ fontWeight: "500", marginBottom: "-5px" }}>Date</h4>
          <p style={{ color: "#4D4D4D" }}>Mar 21, 2024</p>
          <h4 style={{ fontWeight: "500", marginBottom: "-5px" }}>Time</h4>
          <p style={{ color: "#4D4D4D" }}>4:00PM - 8:00PM</p>
          <h4 style={{ fontWeight: "500", marginBottom: "-5px" }}>Location</h4>
          <p style={{ color: "#4D4D4D" }}>
            11140 Magnolia Ave, Riverside, CA 92505
          </p>
        </div>
        <div style={{ marginLeft: "25px" }} className="event-overview-div">
          <h2
            style={{ fontWeight: "400", color: "#000000", marginTop: "30px" }}
          >
            Documents &amp; Files
          </h2>
          <FileUploadComponent brandingColours={brandingColours} />
        </div>
      </div>
      <div className="overviewClientRightPanel">
        <OverviewRightPanelClient brandingColours={brandingColours} />
      </div>
    </div>
  );
};

export default OverviewTab;
