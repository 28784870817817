import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";

const stripePromise = loadStripe('your_stripe_publishable_key');

const StripeOnboarding = () => {
    const [clientSecret, setClientSecret] = useState(null);
    const [accountLink, setAccountLink] = useState(null);
    const [accountId, setAccountId] = useState(null);

    // useEffect(() => {
    //     axios
    //         .post("${process.env.REACT_APP_API_URL}/create-payment-intent", {
    //             items: [{ id: 1, name: "", amount: 40.00 }],
    //         })
    //         .then((resp) => setClientSecret(resp.data.clientSecret));
    // }, []);

    const onboardVendor = async () => {
        const response = await axios.post("${process.env.REACT_APP_API_URL}/create-connected-account", {
            name: "Booth California", // Replace with actual vendor name
            email: "saurabh01nigam06@gmail.com", // Replace with actual vendor email
        });
        setAccountLink(response.data.accountLink);
        setAccountId(response.data.accountId);
    };

    const createTransfer = async (amount) => {
        await axios.post("${process.env.REACT_APP_API_URL}/create-transfer", {
            accountId,
            amount,
        });
    };

    const options = {
        clientSecret,
        theme: "stripe",
    };

    return (
        <div>
            <button onClick={onboardVendor}>Onboard Vendor</button>
            {accountLink && (
                <a href={accountLink} target="_blank" rel="noopener noreferrer">
                    Complete Onboarding
                </a>
            )}
            {accountId && (
                <button onClick={() => createTransfer(40)}>Create Transfer</button>
            )}
            {/* {clientSecret && (
                <Elements stripe={stripePromise} options={options}>
                    <PaymentForm />
                </Elements>
            )} */}
        </div>
    );
};

export default StripeOnboarding;
