import React from "react";

import "../../styling/Dashboard.css";
import "../../styling/ClientPortal.css";
import profilePic from "../../images/happy-birthday-background-sign-tropical-beach-hawaii-63372910.jpg.webp";
import profilePicture from "../../images/John-Wick.jpg.avif";

const LeftPanelClientPortal = ({
  handleBackToAccount,
  clientData,
  brandingColours,
  selectedTab,
}) => {
  const primaryColor = "black";

  const buttonStyle = {
    display: "inline-block",
    padding: "10px, 20px",
    background: brandingColours?.secondaryColor,
    color: brandingColours?.primaryColor,
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    width: "75%",
    height: "35px",
    fontSize: "15px",
    border: "1px solid #d4d4d4"
  };

  const cancelButtonStyle = {
    padding: "10px, 20px",
    background: brandingColours?.primaryColor,
    color: brandingColours?.secondaryColor,
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    width: "75%",
    height: "35px",
    fontSize: "15px",
  };

  const openCancellationModal = {};

  const clientInfo = (
    <div>
      <img
        src={profilePic}
        alt="cover"
        style={{ width: "100%", height: "150px" }}
      />
      <div className="clientProfileContainer">
        <div className="clientProfileImage">
          <img
            src={profilePicture}
            alt="Profile"
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "70%",
              overflow: "hidden",
              marginTop: "-28px",
            }}
          />
        </div>
        <h2
          style={{
            marginLeft: "25px",
            marginRight: "25px",
            color: primaryColor,
          }}
        >
          {clientData && clientData.name ? clientData.name : ""}
        </h2>

        <div
          className="clientDataPanel"
          style={{
            marginLeft: "25px",
            marginRight: "25px",
            fontWeight: "500",
            color: primaryColor, // Spacing between columns and rows
          }}
        >
          {/* Phone */}
          <div>
            <h4 style={{ marginBottom: "-5px" }}>Phone</h4>
            <p style={{ color: "#4D4D4D" }}>
              {clientData && clientData.phone ? clientData.phone : "N/A"}
            </p>
          </div>

          {/* Email */}
          <div>
            <h4 style={{ marginBottom: "-5px" }}>Email</h4>
            <p style={{ color: "#4D4D4D" }}>
              {clientData && clientData.email ? clientData.email : "N/A"}
            </p>
          </div>

          {/* Most Recent Booking */}
          <div>
            <h4 style={{ marginBottom: "-5px" }}>Most Recent Booking</h4>
            <p style={{ color: "#4D4D4D" }}>Mar 21, 2024</p>
          </div>

          {/* Total Events Booked */}
          <div>
            <h4 style={{ marginBottom: "-5px" }}>Total Events Booked</h4>
            <p style={{ color: "#4D4D4D" }}>5</p>
          </div>
        </div>
        {selectedTab !== "landing" && (
          <div
            style={{
              marginLeft: "25px",
              paddingBottom: "10px",
            }}
            className="clientDataPanelButtons"
          >
            <button
              style={buttonStyle}
              onClick={handleBackToAccount}
              className="events-button-hover-underline"
            >
              View All Events
            </button>
            <button
              style={cancelButtonStyle}
              onClick={openCancellationModal}
              className="cancel-reschedule-btn"
            >
              Cancel or Reschedule
            </button>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div>
      <div>{clientInfo}</div>
    </div>
  );
};

export default LeftPanelClientPortal;
