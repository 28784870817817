import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import profilePic from "../images/John-Wick.jpg.avif";
import BookingHeader from "./clientPortal/BookingHeader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select, { components } from "react-select";
import { EventDataContext } from "./EventDataContext";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, loginFailure } from "../authSlice";
import axios from "axios";

const BookABoothLink = () => {
  const [packages, setPackages] = useState([]);
  const [addons, setAddons] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedAddon, setSelectedAddon] = useState(null);
  const [loadingPackages, setLoadingPackages] = useState(true);
  const [loadingAddons, setLoadingAddons] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { EventData, setEventData } = useContext(EventDataContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
  });

  const [loading, setLoading] = useState(false);

  const [userExists, setUserExists] = useState(null);

  const [userId , setUserId] = useState(null);

  const handleUserFormInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    console.log(e.target);
    const { name, value } = e.target;
    console.log(name);
    setEventData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (name, selectedOption) => {
    setEventData((prevData) => ({
      ...prevData,
      [name]: selectedOption,
    }));
  };

  const handleCheckExistingUser = async () => {
    try {
      setLoadingPackages(true); // Optional: indicate loading
      setError(null); // Reset error state

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/client/client-exists`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: userData.email }), // Pass the email in the body
        }
      );

      if (!response.ok) {
        throw new Error("Failed to check if client exists");
      }

      const data = await response.json();
      console.log(data.exists);
      // Process the response
      if (data.data.exists) {
        setUserExists(true);

      } else {
        setUserExists(false);
        setUserData({ email: "" });
        console.log(userExists);
      }
    } catch (err) {
      setError(err.message); // Handle errors
    } finally {
      setLoadingPackages(false); // Reset loading state
    }
  };

  const handleCreateAccountAndEvent = async () => {
    console.log(userData);
    // Sample payload for user creation
    const userPayload = {
      name: userData.name,
      email: userData.email,
      password: userData.password,
      profilePic: "https://picsum.photos/200/300",
      coverpic: "https://source.unsplash.com/1200x400/?nature,landscape",
    };

    try {
      setLoading(true);
      setError(null);

      // Create the user
      const userResponse = await axios.post(
        "${process.env.REACT_APP_API_URL}/client-user/onboard",
        userPayload,
        { withCredentials: true }
      );

      console.log("User created successfully:", userResponse.data.data);

      // Extract the user ID
      const userId = userResponse.data.data.id;

      // Prepare the event payload
      const eventPayload = {
        userId,
        businessId, // Example businessId
        packageId: selectedPackage._id, // Example packageId
        businessUserId: businessId, // Example businessUserId
        eventName: EventData.eventName,
        addonIds: selectedAddon._id, // Example addonIds
        expPaymentAmount: selectedPackage.cost + selectedAddon.cost,
        status: "pending",
        eventDate: EventData.eventDate,
        startTime: new Date(
          `${EventData.eventDate}T${EventData.startTime}`
        ).toISOString(),
        endTime: new Date(
          `${EventData.eventDate}T${EventData.endTime}`
        ).toISOString(),
        address: EventData.location,
        clientName: userData.name,
        payment: "pending",
      };

      // Create the event
      const eventResponse = await axios.post(
        "${process.env.REACT_APP_API_URL}/create-event",
        eventPayload,
        { withCredentials: true }
      );
      navigate("/check-verification");
      console.log("Event created successfully:", eventResponse.data.data);
    } catch (err) {
      console.error("Error occurred:", err);
      setError("Failed to create account or event. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const createEvent = async(userId) => {

      console.log(EventData);
    console.log(userId);
      // Prepare the event payload
      const eventPayload = {
        userId,
        businessId, // Example businessId
        packageId: selectedPackage._id, // Example packageId
        businessUserId: businessId, // Example businessUserId
        eventName: EventData.eventName,
        addonIds: selectedAddon._id, // Example addonIds
        expPaymentAmount: selectedPackage.cost + selectedAddon.cost,
        status: "pending",
        eventDate: EventData.eventDate,
        startTime: new Date(
          `${EventData.eventDate}T${EventData.startTime}`
        ).toISOString(),
        endTime: new Date(
          `${EventData.eventDate}T${EventData.endTime}`
        ).toISOString(),
        address: EventData.location,
        clientName: userData.name,
        payment: "pending",
      };
      try{

      // Create the event
      const eventResponse = await axios.post(
        "${process.env.REACT_APP_API_URL}/create-event",
        eventPayload,
        { withCredentials: true }
      );
      console.log("Event created successfully:", eventResponse.data.data);
    } catch (err) {
      console.error("Error occurred:", err);
      setError("Failed to create account or event. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  const handleLogin = async () => {
    console.log("Login button clicked");
    console.log(userData);

    try {
      // Perform login

      const response = await axios.post(
        "${process.env.REACT_APP_API_URL}/client-user/login",
        { email: userData?.email, password: userData?.password },
        { withCredentials: true }
      );
      dispatch(loginSuccess(response.data));

      const userId = response.data.data.userId; // Assuming login response includes userId

      console.log(response.data);

      // Fetch user data
      const userResponse = await axios.get(
        "${process.env.REACT_APP_API_URL}/client-user",
        { withCredentials: true }
      );
      const clientUserData = userResponse.data;

      // Check if the user is verified
      if (!clientUserData.data.verified) {
        navigate("/verify-email", { state: { userId } });
        return;
      }

      // Fetch events for the logged-in user
      const eventsResponse = await axios.get(
        "${process.env.REACT_APP_API_URL}/client/events",
        { withCredentials: true }
      );

      const events = eventsResponse.data.data;
      console.log(events);

      if (events.length > 0) {
        // Check if there's any event with a pending payment status
        const hasUnpaidEvent = events.some(
          (event) => event.payment === "pending"
        );

        if (hasUnpaidEvent) {
          // If there is an unpaid event, navigate to /booth2 with the unpaid event in the state
          const unpaidEvent = events.find(
            (event) => event.payment === "pending"
          );
          navigate("/booth2", { state: { event: unpaidEvent } });
        } else {
          // If all events are paid, navigate to /client-home with all events
          console.log(userId);
          const newEvent = await createEvent(userId);
          const finalEvent = await axios.get(
            "${process.env.REACT_APP_API_URL}/client/events",
            { withCredentials: true }
          );

          const newEvents = await finalEvent.data.data;
            console.log(newEvents);
          const unpaidEvent = newEvents.find(
            (event) => event.payment === "pending"
          );
          console.log(unpaidEvent);
          navigate("/booth2", { state: {event: unpaidEvent} });
        }
      } else {
        console.log("No events found");
        // Handle the case where there are no events
        // You could navigate to another page or display a message to the user
      }
    } catch (error) {
      console.error("Login or event fetching error:", error);
      dispatch(loginFailure(error.message));
    }
  };

  const options = [
    { value: "wedding", label: "Wedding" },
    { value: "corporate", label: "Corporate" },
    { value: "birthday", label: "Birthday" },
    { value: "others", label: "Others" },
  ];

  const referalOptions = [
    { value: "Friends", label: "Friends or Family" },
    { value: "website", label: "Website" },
    { value: "socialMedia", label: "Social Media" },
    { value: "other", label: "Other" },
  ];

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid #ccc",
      boxShadow: "none",
      height: "50px",
      fontSize: "15px",
    }),
  };

  const [formattedDate, setFormattedDate] = useState("");

  // Restrict past dates
  const today = new Date().toISOString().split("T")[0];

  const handleDateChange = (event) => {
    const selectedDate = event.target.value; // The raw date (YYYY-MM-DD)
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formatted = new Date(selectedDate).toLocaleDateString(
      "en-US",
      options
    );
    setFormattedDate(formatted); // Set the formatted date
  };

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/business/get-packages?businessId=${businessId}`
        );
        if (!response.ok) throw new Error("Failed to fetch packages");
        const data = await response.json();
        setPackages(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoadingPackages(false);
      }
    };

    const fetchAddons = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/business/get-addon?businessId=${businessId}`
        );
        if (!response.ok) throw new Error("Failed to fetch add-ons");
        const data = await response.json();
        setAddons(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoadingAddons(false);
      }
    };

    if (businessId) {
      fetchPackages();
      fetchAddons();
    }
  }, [businessId]);

  const handlePackageSelect = (pkgId) => {
    setSelectedPackage(pkgId);
  };

  const handleAddonSelect = (addonId) => {
    setSelectedAddon(addonId);
  };

  if (loadingPackages || loadingAddons) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}. Please try again later.</div>;
  }

  return (
    <div>
      <BookingHeader />
      <div style={styles.bookAbooth}>
        <div style={styles.panel}>
          <div style={styles.invoiceContent}>
            <h2 style={styles.heading}>Choose Package</h2>
            {packages.map((pkg) => (
              <div key={pkg._id} style={styles.package}>
                <div style={styles.packageHeader}>
                  <h3 style={styles.packageName}>{pkg.name}</h3>
                  <div>
                    ${pkg.cost}
                    <input
                      type="radio"
                      checked={selectedPackage === pkg._id}
                      onChange={() => handlePackageSelect(pkg._id)}
                    />
                  </div>
                </div>
                <div style={styles.packageDescription}>
                  {pkg.description.split("\n").map((feature, i) => (
                    <p key={i}>{feature}</p>
                  ))}
                </div>
              </div>
            ))}

            <h2 style={{ ...styles.heading, marginTop: "30px" }}>Add Ons</h2>
            {addons.map((addon) => (
              <div key={addon._id} style={styles.addon}>
                <img src={profilePic} alt="Add-On" style={styles.addonImage} />
                <div style={styles.addonContent}>
                  <h3 style={styles.addonName}>{addon.name}</h3>
                  <p style={styles.addonDescription}>{addon.description}</p>
                </div>
                <div>
                  ${addon.cost}
                  <input
                    type="radio"
                    checked={selectedAddon === addon._id}
                    onChange={() => handleAddonSelect(addon._id)}
                  />
                </div>
              </div>
            ))}
          </div>
          <div style={styles.eventForm}>
            <h2
              style={{ color: "#000000", fontWeight: "450", marginTop: "0px" }}
            >
              Schedule Your Event
            </h2>

            <h4 style={styles.label}>Name of your event</h4>
            <input
              placeholder=" Event Name"
              style={{ ...styles.input, color: "#625C73" }}
              value={EventData.eventName}
              name="eventName"
              onChange={handleInputChange}
            />

            <h4 style={styles.label}>What is your event?</h4>

            <Select
              options={options}
              styles={customStyles}
              components={{
                IndicatorSeparator: () => null,
              }}
              placeholder="Wedding"
              name="eventType"
              id="eventType"
              onChange={(selectedOption) =>
                setEventData((prevData) => ({
                  ...prevData,
                  eventType: selectedOption.value,
                }))
              }
              value={options.find(
                (option) => option.value === EventData.eventType
              )}
            />

            <h4 style={styles.label}>How did you hear about us?</h4>
            <Select
              options={referalOptions}
              styles={customStyles}
              components={{
                IndicatorSeparator: () => null,
              }}
              placeholder="Social Media"
              name="referralSource"
              id="referralSource"
              onChange={(selectedOption) =>
                setEventData((prevData) => ({
                  ...prevData,
                  referralSource: selectedOption.value,
                }))
              }
              value={referalOptions.find(
                (option) => option.value === EventData.referralSource
              )}
            />

            <h4 style={styles.label}>Choose a Date</h4>

            <input
              placeholder="  Choose a date"
              style={{ ...styles.input, color: "#625C73" }}
              type="date"
              id="eventDate"
              name="eventDate"
              value={EventData.eventDate}
              min={today}
              onChange={handleInputChange}
            />

            <h4 style={styles.label}>What time is your event?</h4>
            <div style={{ display: "flex", width: "100.5%" }}>
              <input
                placeholder="  Enter time"
                style={{ ...styles.input, color: "#625C73", marginRight: "1%" }}
                type="time"
                id="startTime"
                name="startTime"
                value={EventData.startTime}
                onChange={handleInputChange}
              />
              <input
                placeholder="  Enter time"
                style={{ ...styles.input, color: "#625C73" }}
                type="time"
                id="endTime"
                name="endTime"
                value={EventData.endTime}
                onChange={handleInputChange}
              />
            </div>

            <h4 style={styles.label}>Where is your event located?</h4>
            <input
              placeholder="  Enter location"
              style={{ ...styles.input, color: "#625C73" }}
              name="location"
              id="location"
              value={EventData.location}
              onChange={handleInputChange}
            />

            <h4 style={styles.label}>Tell us about your event!</h4>
            <textarea
              placeholder="  Add additional details"
              style={{
                ...styles.input,
                height: "100px",
                color: "#625C73",
                fontFamily: "poppins",
              }}
              value={EventData.details}
              name="details"
              id="details"
              onChange={handleInputChange}
            />
          </div>
          <div
            style={{
              backgroundColor: "#ffffff",
              marginTop: "35px",
              borderRadius: "3px",
              padding: "8%",
              paddingTop: "3%",
            }}
          >
            <h2
              style={{ color: "#000000", fontWeight: "600", marginTop: "0px" }}
            >
              Log in or sign up to book
            </h2>
            <p style={{ color: "#625C73", fontSize: "15px" }}>
              Sign up for our photobooth client portal to enjoy an easy booking
              process. View all your bookings and manage each event by easily
              viewing invoices, completing questionnaires, giving design
              feedback, and access to downloadable galleries. Make your
              photobooth experience unforgettable with just a few clicks!
            </p>
            <input
              placeholder="  Email"
              style={{ ...styles.input, color: "#625C73" }}
              type="email"
              value={userData.email}
              name="email"
              id="email"
              onChange={handleUserFormInputChange}
            />
            {userExists === null && (
              <button
                style={{
                  marginTop: "15px",
                  height: "40px",
                  width: "100%",
                  background: "#260F99",
                  border: "1px solid #43425D",
                  color: "#FFFFFF",
                  fontSize: "17px",
                  fontWeight: "450",
                }}
                onClick={handleCheckExistingUser}
              >
                Continue
              </button>
            )}
            {userExists === true && (
              <>
                <input
                  placeholder="  Password"
                  style={{
                    ...styles.input,
                    color: "#625C73",
                    marginTop: "10px",
                  }}
                  type="password"
                  value={userData.password}
                  name="password"
                  onChange={handleUserFormInputChange}
                />
                <button
                  style={{
                    marginTop: "15px",
                    height: "40px",
                    width: "100%",
                    background: "#260F99",
                    border: "1px solid #43425D",
                    color: "#FFFFFF",
                    fontSize: "17px",
                    fontWeight: "450",
                  }}
                  onClick={handleLogin}
                >
                  Continue
                </button>
              </>
            )}
            {userExists === false && (
              <>
                <input
                  placeholder="  Name"
                  style={{
                    ...styles.input,
                    color: "#625C73",
                    marginTop: "10px",
                  }}
                  type="text"
                  value={userData.name}
                  name="name"
                  id="name"
                  onChange={handleUserFormInputChange}
                />
                <input
                  placeholder="  Password"
                  style={{
                    ...styles.input,
                    color: "#625C73",
                    marginTop: "10px",
                  }}
                  type="password"
                  value={userData.password}
                  name="password"
                  id="password"
                  onChange={handleUserFormInputChange}
                />
                <input
                  placeholder="  Confirm Password"
                  style={{
                    ...styles.input,
                    color: "#625C73",
                    marginTop: "10px",
                  }}
                  type="password"
                  value={userData.confirmPassword}
                  onChange={handleUserFormInputChange}
                  name="confirmPassword"
                  id="confirmPassword"
                />
                <button
                  style={{
                    marginTop: "15px",
                    height: "40px",
                    width: "100%",
                    background: "#260F99",
                    border: "1px solid #43425D",
                    color: "#FFFFFF",
                    fontSize: "17px",
                    fontWeight: "450",
                  }}
                  onClick={handleCreateAccountAndEvent}
                >
                  Create Account
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  bookAbooth: {
    display: "flex",
    justifyContent: "center",
    padding: "20px",
    backgroundColor: "#F2F2F2",
  },
  panel: {
    background: "#f2f2f2",
    width: "43%",
  },
  invoiceContent: {
    width: "95%",
    background: "#FFFFFF",
    padding: "20px",
    borderRadius: "8px",
  },
  heading: {
    color: "#000000",
    margin: "0 0 30px 0",
  },
  package: {
    marginTop: "20px",
    padding: "20px",
    border: "1px solid #D9D9D9",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
  },
  packageHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  packageName: {
    color: "#000000",
    margin: "0",
  },
  packageDescription: {
    color: "#4D4D4D",
  },
  addon: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #D9D9D9",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    padding: "10px",
    marginBottom: "10px",
  },
  addonImage: {
    width: "50px",
    height: "50px",
    marginRight: "10px",
    borderRadius: "8px",
    objectFit: "cover",
  },
  addonContent: {
    flex: "1",
  },
  addonName: {
    color: "#000000",
    margin: "0 0 5px 0",
  },
  addonDescription: {
    color: "#4D4D4D",
    margin: "0",
  },
  label: {
    color: "#080700",
    marginTop: "10px",
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    height: "50px",
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    fontSize: "15px",
    fontFamily: "poppins",
  },
  eventForm: {
    backgroundColor: "#FFFFFF",
    padding: "8%",
    borderRadius: "3px",
    marginTop: "35px",
  },
};

export default BookABoothLink;
